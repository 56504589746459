@font-face {
    font-family: 'Manrope';
    src: url('https://cdn-staging.crikle.com/static/fonts/Manrope-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Manrope';
    src: url('https://cdn-staging.crikle.com/static/fonts/Manrope-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Manrope';
    src: url('https://cdn-staging.crikle.com/static/fonts/Manrope-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Manrope';
    src: url('https://cdn-staging.crikle.com/static/fonts/Manrope-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Manrope';
    src: url('https://cdn-staging.crikle.com/static/fonts/Manrope-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

/* legacy (delete) start */
@font-face {
    font-family: 'SFProDisplay';
    src: url('https://cdn-staging.crikle.com/static/fonts/SF-Pro-Display-Regular.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'SFProDisplay';
    src: url('https://cdn-staging.crikle.com/static/fonts/SF-Pro-Display-Medium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'SFProDisplay';
    src: url('https://cdn-staging.crikle.com/static/fonts/SF-Pro-Display-Semibold.otf') format('opentype');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'SFProDisplay';
    src: url('https://cdn-staging.crikle.com/static/fonts/SF-Pro-Display-Semibold.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'SFProDisplayMedium';
    src: url('https://cdn-staging.crikle.com/static/fonts/SF-Pro-Display-Medium.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'SFProDisplay-Medium';
    src: url('https://cdn-staging.crikle.com/static/fonts/SF-Pro-Display-Medium.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'SFProDisplay-Semibold';
    src: url('https://cdn-staging.crikle.com/static/fonts/SF-Pro-Display-Semibold.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'SFProText';
    src: url('https://cdn-staging.crikle.com/static/fonts/SF-Pro-Text-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'SFProTextMedium';
    src: url('https://cdn-staging.crikle.com/static/fonts/SF-Pro-Text-Medium.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'LucidaGrande';
    src: url('https://cdn-staging.crikle.com/static/fonts/LucidaGrandeRegular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
/* legacy (delete) start */

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    /* color: var(--color-text);
    font-family: 'SFProDisplay', Helvetica, Arial, sans-serif; */
    font-family: 'Manrope', Helvetica, Arial, sans-serif;
}

body.widget {
    overflow: hidden auto;
}

body.register,
#root.register {
    overflow-y: scroll;
}

.Crikle-App.register {
    height: auto;
}

:focus {
    outline: 0;
}
