:root {
    --color-primo: #14d3be;
    --color-text: #3d4745;
    --color-minor-text: #bbc1bf;
    --color-empty-text: #56615f;
    --color-back: #ffffff;
    --color-back-opa: #ffffff00;
    --color-minor-back: #f0f4f3;
    --color-box-shadow: #aeaeae;
    --color-back-border: #bbc1bf;
    --color-sheet: #181919;
    --color-darkest: #000000;
    --color-placeholder-dark: #434847;
    --color-text-dark: #f6f9f8;

    --color-accent: #14d3be;
}

a {
    text-decoration: none;
    color: #06cac0;
}

.Crikle-App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    min-width: 100%;
}

.Waiter-Gif {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.Spacer-Vertical {
    flex: 1;
}

.GrayPlaceholder::placeholder {
    text-shadow: 0px 0px 0px var(--color-back-border);
}
.GrayPlaceholder::-webkit-input-placeholder {
    text-shadow: 0px 0px 0px var(--color-back-border);
}
.GrayPlaceholder::-moz-placeholder {
    text-shadow: 0px 0px 0px var(--color-back-border);
}
.GrayPlaceholder:-ms-input-placeholder {
    text-shadow: 0px 0px 0px var(--color-back-border);
}
.GrayPlaceholder:-moz-placeholder {
    text-shadow: 0px 0px 0px var(--color-back-border);
}
.GrayPlaceholder textarea::placeholder {
    color: #434847;
    opacity: 1;
}
.GrayPlaceholder textarea::-webkit-input-placeholder {
    color: #434847;
    opacity: 1;
}
.GrayPlaceholder textarea::-moz-placeholder {
    color: #434847;
    opacity: 1;
}
.GrayPlaceholder textarea:-ms-input-placeholder {
    color: #434847;
    opacity: 1;
}
.GrayPlaceholder textarea:-moz-placeholder {
    color: #434847;
    opacity: 1;
}

.ytp-show-watch-later-title {
    display: none !important;
}

.vjs-tech {
    object-fit: cover;
}

.MuiTooltip-tooltip {
    background-color: #242928 !important;
    margin: 4px 0px !important;
}

.vjs-upcoming-video {
    display: none;
}
.vjs-recommendations-overlay {
    display: none;
}
.vjs-big-play-button {
    top: unset !important;
    bottom: 10px !important;
    left: 10px !important;
    font-size: 3em !important;
    line-height: 1.4em !important;
    height: 1.5em !important;
    width: 1.8em !important;
}

.iv-snap-view {
    top: 6px !important;
    left: -30px !important;
    transform: scale(0.5) !important;
}

.iv-zoom-slider {
    display: none !important;
}

.iv-image {
    z-index: 1 !important;
}

.pac-container {
    width: 200px !important;
    font-family: SFProDisplay;
}
.pac-container:after {
    background-image: none !important;
    height: 0px;
}

.pac-icon {
    background-image: url('./assets/icons/pin-default.svg') !important;
    background-size: 13px;
    background-repeat: no-repeat;
    background-position: center;
}

.autocompleteDropdownPositionTop {
    margin-top: -200px;
}

@keyframes fadeBorder {
    /* from {border: solid 3px #14d3be;}
  to {border: solid 3px transparent;} */
    0% {
        border: solid 3px rgba(20, 211, 190, 1);
    }
    50% {
        border: solid 3px rgba(20, 211, 190, 0.7);
    }

    75% {
        border: solid 3px rgba(20, 211, 190, 0.45);
    }

    100% {
        border: solid 3px rgba(20, 211, 190, 0);
    }
}
@-webkit-keyframes fadeBorder {
    from {
        border: solid 3px #14d3be;
    }
    to {
        border: solid 3px transparent;
    }
}

.miniaturePin {
    scale: 0.245;
    margin-left: -6px;
    margin-top: -9px;
}

/*Page Transitions | fadeIn*/
.fadeIn-enter {
    opacity: 0;
}
.fadeIn-enter-active {
    opacity: 1;
    transition: opacity 300ms ease;
}
.fadeIn-exit {
    opacity: 1;
}
.fadeIn-exit-active {
    opacity: 0;
    transition: opacity 300ms ease;
}
.fadeIn-exit-done {
    display: none;
}

body > div#hs-eu-cookie-confirmation {
    z-index: 1399 !important;
}

.menu-hide-popover {
    width: 240px;
    height: 0px;
}

.menu-hide-popover > div {
    max-height: 176px;
}
